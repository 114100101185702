import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Item from "../components/Item"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

import "../utils/image"

export const query = graphql`
  query {
    maturation: file(relativePath: { eq: "fromagerie/maturation.jpg" }) {
      ...squareImage
    }
    empresurage: file(relativePath: { eq: "fromagerie/empresurage.jpg" }) {
      ...squareImage
    }
    caillage: file(relativePath: { eq: "fromagerie/caillage.jpg" }) {
      ...squareImage
    }
    moulage: file(relativePath: { eq: "fromagerie/moulage.jpg" }) {
      ...squareImage
    }
    retournement: file(relativePath: { eq: "fromagerie/retournement.jpg" }) {
      ...squareImage
    }
    demoulage: file(relativePath: { eq: "fromagerie/demoulage.jpg" }) {
      ...squareImage
    }
    ressuyage: file(relativePath: { eq: "fromagerie/ressuyage.jpg" }) {
      ...squareImage
    }
    salage: file(relativePath: { eq: "fromagerie/salage.jpg" }) {
      ...squareImage
    }
    affinage: file(relativePath: { eq: "fromagerie/affinage.jpg" }) {
      ...squareImage
    }
  }
`

const Fromagerie = () => {
  const description = `Les fromages sont fabriqués de la même façon toute l’année. Cependant, certains paramètres technologiques comme l’humidité et la température de l’air peuvent varier au cours de la journée ainsi que la qualité du lait qui varie au cours de l’année. Le goût des fromages aura donc lui aussi tendance à changer au gré des saisons. Une fois les chèvres traites, le processus de fabrication, peut commencer.`

  return (
    <Layout>
      <SEO title="La Fromagerie" description={description} />
      <div style={{ marginBottom: "1.8rem" }}>
        <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
          La Fromagerie
        </h1>
        <p style={{ textAlign: "justify" }}>{description}</p>
      </div>
      <StaticQuery
        query={query}
        render={({
          affinage,
          caillage,
          demoulage,
          empresurage,
          maturation,
          moulage,
          ressuyage,
          retournement,
          salage,
        }) => (
          <>
            <Item
              image={maturation}
              title="La maturation du lait"
              text={
                <>
                  <p>
                    Après la traite du soir, le lait est refroidi à 13°C et des
                    ferments y sont ajoutés. Ces ferments, composés de
                    bactéries, de levures et de moisissures, ont pour rôle
                    d’acidifier le lait et préparer un affinage éventuel. Ces
                    micro-organismes commencent donc à consommer le lactose du
                    lait pour se multiplier et le transformant en acide
                    lactique. Le lait commence à « tourner ».
                  </p>
                  <p>
                    Le lait de la traite du lendemain matin est additionné au
                    lait de la veille et l’ensemble est porté à 21°C.
                  </p>
                </>
              }
            ></Item>
            <Item
              image={empresurage}
              title="L'emprésurage"
              text={
                <>
                  <p>
                    Une fois à 21°C, le lait est mis en bassine et on y ajoute
                    la présure. La présure est une substance exclusivement
                    d’origine animale, prélevée dans les caillettes des jeunes
                    ruminants, ici les chevreaux, et qui contient des enzymes
                    pour faire cailler le lait. Ces enzymes présentes dans la
                    présure vont digérer les protéines du lait.
                  </p>
                </>
              }
              left
            ></Item>
            <Item
              image={caillage}
              title="Le caillage "
              text={
                <p>
                  La durée pendant laquelle le lait doit reposer pour cailler
                  complètement est de 24h. Durant cette période, les ferments
                  continuent à consommer le lactose et en principe, il n’en
                  reste plus dans les fromages de chèvres traditionnels qui sont
                  alors appelés techniquement « fromages lactiques ». L’action
                  conjointe des ferments et de la présure permet le passage du
                  lait liquide à du caillé qui lui est solide.
                </p>
              }
            ></Item>
            <Item
              image={moulage}
              title="Le moulage"
              text={
                <p>
                  Une fois que le caillé est bien ferme, on le met en moule
                  pendant 24h. Cette étape permet la séparation du « petit lait
                  », ou lactosérum, et du caillé qui lui reste dans le moule.
                  C’est à cette étape que l’on donne une forme particulière au
                  fromage ; bûchette, crottin, rond, et que l’on fabrique les
                  faisselles.
                </p>
              }
              left
            ></Item>
            <Item
              image={retournement}
              title="Le retournement"
              text={
                <p>
                  Les fromages sont retournés dans leurs moules afin de leur
                  donner une forme régulière sur chaque face. Deux retournements
                  sont réalisés, le premier une heure après le moulage, et le
                  second cinq heures après.
                </p>
              }
            ></Item>
            <Item
              image={demoulage}
              title="Le démoulage"
              text={
                <p>
                  Le lendemain du moulage, les fromages sont démoulés et placés
                  sur des grilles d’affinage recouvertes d’un grillage à maille
                  fine. Ce dernier est en place une journée et permet au fromage
                  de s’égoutter sans passer à travers la grille.
                </p>
              }
              left
            ></Item>
            <Item
              image={ressuyage}
              title="Le ressuyage"
              text={
                <p>
                  Une fois démoulé, le fromage doit s’égoutter entre 24 et 36
                  heures à 20°C suivant la saison avant d'être salé. Le fromage
                  encore mou est retourné deux fois par jour pour qu’il
                  s’égoutte de manière homogène et afin d’éviter qu’il ne
                  s’évase.
                </p>
              }
            ></Item>
            <Item
              image={salage}
              title="Le salage"
              text={
                <>
                  <p>
                    Une fois bien égouttés, les fromages sont salés
                    individuellement et sur chaque face. Le salage est très
                    important car il permet au fromage de finir son égouttage,
                    la sélection des moisissures qui se développeront à sa
                    surface, la formation de la croûte du fromage et enfin, il
                    donne davantage de goût au fromage. Un saupoudrage au
                    charbon végétal peut être réalisé à cette étape si l’on
                    souhaite faire des fromages cendrés.
                  </p>
                  <p>
                    Une fois salé, le fromage peut être vendu directement frais
                    ou aromatisé mais il peut aussi être affiné.
                  </p>
                </>
              }
              left
            ></Item>
            <Item
              image={affinage}
              title="L'affinage"
              text={
                <>
                  <p>
                    Les fromages sont affinés dans un hâloir à 12°C pendant 5 à
                    25 jours. Les fromages, raffermit par le froid et
                    l’égouttage ne sont désormais retournés que trois fois par
                    semaine. C’est pendant cette période que les fromages vont
                    prendre tous leurs arômes.
                  </p>
                  <p>
                    Le processus de fabrication d’un fromage frais avant
                    l’affinage est long ; entre l’ajout des ferments dans le
                    lait et le salage, il se passe quatre jours.
                  </p>
                </>
              }
            ></Item>
          </>
        )}
      />
    </Layout>
  )
}

export default Fromagerie
